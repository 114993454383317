import * as Sentry from '@sentry/react'
import { Integrations } from '@sentry/tracing'

const getEnv = () => {
  const host = window.location.host.split('.').slice(1).join('.')
  const envs = {
    'dev-ipaygateway.com': 'dev',
    'stage-ipaygateway.com': 'stage',
    'sandbox-clogic.app': 'qiwi-stage',
    'clogic.app': 'qiwi-prod',
  }

  return envs[(host as keyof typeof envs)]
}

const initSentry = () => {
  if (process.env.NODE_ENV === 'development') {
    return
  }

  const env = getEnv()

  if (!env) return

  Sentry.init({
    dsn: 'https://22b26af9a0224b0996a0f4dd8999bb96@o57197.ingest.sentry.io/5941150',
    normalizeDepth: 7,
    autoSessionTracking: true,
    integrations: [new Integrations.BrowserTracing()],
    tracesSampleRate: 1.0,
    environment: env,
  })
}

export default initSentry
