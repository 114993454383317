export const API_LINKS = {
  countries: '/api/v1/countries',
  'account-info': '/api/v1/account-info',
  'payment-settings': '/api/v1/payment-settings',
  'payment-methods': '/api/v1/payment-methods',
  'existing-cards': '/api/v1/existing-cards',
  'card-payment': '/api/v1/card-payment',
  'card-token-payment': '/api/v1/card-token-payment',
  'apm-payment': '/api/v1/apm-payment',
  getNewAddressUrl: '/api/v1/bitcoin-payment',
  redirectURL: '/thank-you#thank-you',
  fetchApmRPC: '/mz_cashier_apm_rpc',
  fetchApm: '/api/v1/apm',
  newDepositAPM_URL: '/mz_cashier_apm_deposit',
  currencyConversion: '/api/v1/match-processing-money',
  translates: '/api/v1/translates',
  paymentOptions: '/api/v1/match-payment-options',
}

export const EXISTING_LANG = [
  'ar',
  'de',
  'en',
  'es',
  'it',
  'nl',
  'pt',
  'ru',
  'sv',
  'fr',
  'pl',
  'lt',
  'ms',
  'th',
  'pt-br',
  'tl',
  'hi',
  'ja',
  'tr',
  'vi',
  'ko',
  'zh',
  'cn',
  'el',
  'id',
]
