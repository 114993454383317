interface IConfig {
  app: {
    [T in string]: string
  }
  brand: {
    name: string
    phone: string
    brandUrl: string
    contactUsPage: string
    brandEmail: string
    DODLink: string
    forceDeclaration?: boolean
    brandColor?: string
  }
}

class Config {
  brand: IConfig['brand'] = {
    name: '',
    phone: '',
    brandUrl: '',
    contactUsPage: '',
    brandEmail: '',
    DODLink: '',
    forceDeclaration: false,
  }

  app: IConfig['app'] = {}

  getConfig = () => {
    return {
      brand: this.brand,
      app: this.app,
    }
  }

  setConfig = (config: IConfig) => {
    this.brand = config.brand
    this.app = config.app
  }
}

export default new Config()
