import * as yup from 'yup'
import cardValidator from 'card-validator'

const extendYupValidators = () => {
  cardValidator.creditCardType.addCard({
    niceType: 'Visa',
    type: cardValidator.creditCardType.types.VISA,
    patterns: [4],
    gaps: [4, 8, 12],
    lengths: [13, 16, 18, 19], // add support for old, deprecated 13 digit visas
    code: {
      name: 'CVV',
      size: 3,
    },
  })

  cardValidator.creditCardType.addCard({
    niceType: 'Unknown',
    type: 'unknown',
    patterns: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9],
    gaps: [4, 8, 12],
    lengths: [12, 13, 14, 15, 16, 17, 18, 19],
    code: {
      name: 'CVV',
      size: 3,
    },
  })

  cardValidator.creditCardType.updateCard('unknown', {
    code: {
      name: 'CVV',
      size: 4,
    },
  })

  cardValidator.creditCardType.updateCard('jcb', {
    patterns: [2131, 35],
  })

  yup.addMethod(yup.string, 'expMonth', function (message) {
    return this.test({
      message,
      name: 'expMonth',
      test(value) {
        return !!value && Array.from({ length: 12 }, (_, i) => `${i + 1}`.padStart(2, '0'))
          .includes(value)
      },
    })
  })

  yup.addMethod(yup.string, 'expYear', function (message) {
    return this.test({
      message,
      name: 'expYear',
      test(value) {
        return !!value && Array.from(
          { length: 16 },
          (_, i) => `${i + new Date().getFullYear()}`,
        ).includes(value)
      },
    })
  })

  yup.addMethod(yup.string, 'cardNumber', function (message) {
    return this.test({
      message,
      name: 'cardNumber',
      test(value) {
        return cardValidator.number(value).isValid
      },
    })
  })
}

export default extendYupValidators
