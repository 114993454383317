// eslint-disable-next-line import/no-unassigned-import
import 'abortcontroller-polyfill/dist/polyfill-patch-fetch'
import React from 'react'
import ReactDOM from 'react-dom'
import { Provider } from 'mobx-react'
import config from 'config'
import { initSentry, observeHeight, extendYupValidators, initApplePay } from './global/services'
import setupPolyfills from './global/polyfills'
import iFrameParams from './global/data/iFrameParams'
import './index.css'

const MOUNT_NODE = document.getElementById('root')

const setupServices = (): void => {
  initSentry()
  observeHeight()
  initApplePay()
}

let render = () => {
  const { app } = config
  const Layout = React.lazy(() => import(/* webpackChunkName: 'Layout' */ `./layouts/${app.Layout}`))

  ReactDOM.render(
    <React.StrictMode>
      <Provider>
        <React.Suspense fallback={null}>
          <Layout />
        </React.Suspense>
      </Provider>
    </React.StrictMode>,
    MOUNT_NODE,
  )
}

if (module.hot) {
  // Development render functions
  const renderApp = render
  const renderError = (error: any) => {
    ReactDOM.render(<div>{error}</div>, MOUNT_NODE)
  }

  render = () => {
    try {
      renderApp()
    } catch (error: any) {
      renderError(error)
    }
  }
}

const run = () => {
  setupServices()
  setupPolyfills()
  extendYupValidators()

  const { brand } = iFrameParams

  import(/* webpackChunkName: 'config' */ `./config/${brand}`)
    .then(module => {
      const brandConfig = module.default

      config.setConfig(brandConfig)

      return brandConfig
    })
    .then(brandConfig => {
      render()
      module.hot?.accept?.(`./layouts/${brandConfig.app.Layout}`, render)
    })
}

run()
