import ResizeObserver from 'resize-observer-polyfill'

const observeHeight = () => {
  const observer = new ResizeObserver((entries) => {
    entries.forEach(entry => {
      const { height } = entry.contentRect
      const fixHeight = height + 10

      parent.postMessage({ type: 'height', height: fixHeight }, '*')
    })
  })

  observer.observe(document.body)
}

export default observeHeight
